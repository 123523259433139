import { RecordProps, SaveRecordProps, TableMetaProps } from "@props/RecordProps";
import React, { CSSProperties, useMemo } from "react";
import { Form, FormInstance } from "antd";
import { getEditableController, getValuePropName } from "@kernel/EditableComponentsMapping";
import { DisplayRenderProps, EditableRenderProps } from "./EntityAttributesTable";
import { buildPath } from "@utils/eventBus/EventBus";
import { getDisplayRenderFunction } from "@kernel/DisplayComponentsMapping";

export interface EntityAttributesCellProps {
  column: TableMetaProps;
  record: SaveRecordProps;
  editMode: boolean;
  path?: string;
  ownerId?: number;
  innerForm: FormInstance;
  ownerClass: string;
  zIndex: number;
  style?: CSSProperties;
}

export const EntityAttributesCell: React.FC<EntityAttributesCellProps> = (props: EntityAttributesCellProps) => {
  const {
    column, record, editMode, path, ownerId, innerForm, zIndex, style, ownerClass,
  } = props;
  const { key, type, title, multiple, enumOptions, updatable, defaultValue } = column;
  const EditableRender: React.FC<EditableRenderProps> | undefined = useMemo(() => {
    const editableController = getEditableController(type);
    if (!editableController) {
      return undefined;
    }
    const columnPath = buildPath(path ?? "", key);
    return ({ record }) => {
      return editableController({
        updatable: editMode && ((ownerId && ownerId > 0) ? updatable !== false : true),
        fieldValue: innerForm.getFieldValue(key) ?? defaultValue,
        type,
        fieldTitle: title,
        form: innerForm,
        key,
        domainName: ownerClass,
        record,
        zIndex,
        multiple: !!multiple,
        column,
        style: style ?? {
          flex: 1,
        },
        enumOptions,
        path: columnPath,
      });
    };
  }, [column, path, defaultValue, editMode, enumOptions, innerForm, key, multiple, ownerId, title, type, updatable, zIndex, style, ownerClass]);
  const DisplayRender: React.FC<DisplayRenderProps> = useMemo(() => ({ record }) => {
    const value = record[key];
    const displayController = getDisplayRenderFunction({
      column,
      enumValues: {},
      objectValues: {},
      domainName: "",
      page: "LIST",
      zIndex,
      form: innerForm,
    });
    return displayController(value, record as RecordProps);
  }, [column, innerForm, key, zIndex]);

  // console.log('EntityAttributesCell re-rendered', column.key);

  return (<Form.Item
    className={`entity-attribute-cell-td-${column.type} entity-attribute-cell-td-${column.key}`}
    name={key}
    key={key}
    valuePropName={getValuePropName(type)}
    noStyle={true}
  >
    {editMode && column.editable && EditableRender
      // ? a
      ? EditableRender({ record })
      : <DisplayRender key={`entity-attribute-cell-readonly-${column.key}`} record={record}/>}
  </Form.Item>);
};
