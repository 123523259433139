import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchEnabledActionsForClass } from '@utils/FetchUtils';
import { ActionProps, ActionButtonsProps, ActionMode, ActionGroupProps } from "@props/RecordProps";
import { isArrayEqual } from '@utils/ObjectUtils';
import { LoadingOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import './App.less';
import { Dropdown, Menu, Space } from 'antd';
import ActionIcon from '../../components/icons/ActionIcon';
import ActionButton from './ActionButton';

const ActionButtons = (props: ActionButtonsProps): ReactElement => {
  const {
    domainName, fetchDataCallback, selectedData, mode, zIndex,
    visiblePopover, setVisiblePopoverCallback, displayLabel: displayLabelProp,
    ownerClass, ownerId, columnNameInOwnerClass, direction, refreshKey,
    labelField,
  } = props;
  const { t } = useTranslation();
  const ref = useRef<HTMLSpanElement>(null);

  /** Action 列表 */
  const [actions, setActions] = useState<Array<ActionProps>>([]);
  /** 是否在载入中 */
  const [loading, setLoading] = useState<boolean | undefined>();
  /** 每个 Action 的显示组件 */
  const [elements, setElements] = useState<Array<ReactElement>>([]);
  const [selectedIds, setSelectedIds] = useState<Array<number>>([]);
  const [actionGroups, setActionGroups] = useState<Array<ActionGroupProps>>([]);

  const isDisplayClassAction = (mode === 'class');
  const isMultipleMode = (mode === 'multiple');
  const isSingleMode = (mode === 'single');
  const alignDirection: "vertical" | "horizontal" = direction ?? (isSingleMode ? 'vertical' : 'horizontal');
  const displayLabel: boolean = (displayLabelProp ?? true);

  /** 切换不同的 Domain 显示后，初始化 loading 状态，到后台获取 class action 的列表 */
  useEffect(() => {
    if (loading !== undefined) {
      setLoading(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domainName]);

  const fetchActionList = useCallback((actionMode: ActionMode, newSelectedIds: number[]) => {
    fetchEnabledActionsForClass({
      domainName, type: actionMode, ids: newSelectedIds,
      ownerClass, ownerId, columnNameInOwnerClass
    }).then(json => {
      const enabledActions = json?.filter(a => a.enable === true);
      const filterByModeActions = isSingleMode ?
        enabledActions?.filter(a => ['OBJECT_SINGLE_MULTIPLE', 'OBJECT_SINGLE'].includes(a.mode)) : enabledActions;
      if (!isArrayEqual(filterByModeActions, actions)) {
        setActions(filterByModeActions);
      }
      return filterByModeActions;
    }).then(actions => {
      const actionGroups: Array<ActionGroupProps> = [];
      actions?.forEach(action => {
        const actionGroup = actionGroups.find(ag => ag.id === action.group?.id);
        if (actionGroup) {
          if (actionGroup.actions) {
            actionGroup.actions.push(action);
          } else {
            actionGroup.actions = [action];
          }
        } else {
          if (action.group) {
            actionGroups.push({
              ...action.group,
              actions: [action]
            });
          }
        }
      });
      setActionGroups(actionGroups);
    })
      .catch(e => console.error(`Failed to get action list for [${domainName}, mode: ${actionMode}, selectedIds: ${newSelectedIds}]`, e))
      .finally(() => {
        setLoading(false);
      });
  }, [actions, columnNameInOwnerClass, domainName, ownerClass, ownerId, isSingleMode]);

  // 如果选择的数据为 1 条，同时获取 single mode 的 action
  const modeParamForFetchAction: ActionMode = (isMultipleMode && selectedData?.length === 1) ?
    'single&multiple' : mode;

  useEffect(() => {
    if (refreshKey !== null) {
      fetchActionList(modeParamForFetchAction, selectedData?.map(d => d.id) ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey, modeParamForFetchAction , selectedData]);

  useEffect(() => {
    // 根据当前选中的表格数据到后台获取可用的 Action 列表 */
    // 如果当前选中的数据只有一条，则获取 single, single_multiple, multiple 类型的 action
    // 如果当前选中的数据有多条，则获取 multiple, single_multiple 类型的 action
    // 如果当前没有选中数据，则获取 class 类型的 action
    const newSelectedIds = selectedData?.map(d => d.id) ?? [];
    const selectedDataNoChange = isArrayEqual(newSelectedIds, selectedIds);
    const notClassActionAndSelectedDataNoChange = (selectedDataNoChange && !isDisplayClassAction);
    const classActionLoadedOrLoading = (isDisplayClassAction && loading !== undefined) && !(selectedIds.length !== 0);
    if (notClassActionAndSelectedDataNoChange || classActionLoadedOrLoading) {
      return;
    }
    const currentSelectedRowSize = selectedIds?.length;
    const newSelectedRowSize = newSelectedIds.length;
    const selectFirstRow = (currentSelectedRowSize === 0 && newSelectedRowSize !== 0);
    const unSelectedLastRow = (currentSelectedRowSize !== 0 && newSelectedRowSize === 0);

    if (loading === undefined || selectFirstRow || unSelectedLastRow) {
      setLoading(true);
    }
    setSelectedIds(newSelectedIds);
    fetchActionList(modeParamForFetchAction, newSelectedIds);
  }, [
    domainName, mode, selectedData, selectedIds, loading, actions, fetchActionList, isMultipleMode,
    isDisplayClassAction, modeParamForFetchAction
  ]);

  const buildActionElem = useCallback((action: ActionProps) => {
    return (isSingleMode || action.supportFineTuning) ? (<ActionButton
      visiblePopover={visiblePopover}
      setVisiblePopoverCallback={setVisiblePopoverCallback}
      mode={mode}
      domainName={domainName}
      zIndex={zIndex + 1}
      selectedData={selectedData}
      action={action}
      labelField={labelField}
      displayLabel={displayLabel ?? true}
      fetchDataCallback={() => {
        fetchDataCallback();
        fetchActionList(modeParamForFetchAction, selectedIds);
      }}
      key={action.name}
      ownerClass={ownerClass}
      ownerId={ownerId}
      columnNameInOwnerClass={columnNameInOwnerClass} />) : (<ActionButton
        visiblePopover={visiblePopover}
        setVisiblePopoverCallback={setVisiblePopoverCallback}
        mode={mode}
        domainName={domainName}
        zIndex={zIndex + 1}
        selectedData={selectedData}
        action={action}
        displayLabel={displayLabel ?? true}
        fetchDataCallback={() => {
          fetchDataCallback();
          fetchActionList(modeParamForFetchAction, selectedIds);
        }}
        key={action.id}
        ownerClass={ownerClass}
        ownerId={ownerId}
        labelField={labelField}
        columnNameInOwnerClass={columnNameInOwnerClass} />);
  }, [
    columnNameInOwnerClass, displayLabel, domainName, fetchActionList, fetchDataCallback,
    isSingleMode, mode, modeParamForFetchAction, ownerClass, ownerId, selectedData, selectedIds,
    setVisiblePopoverCallback, visiblePopover, zIndex, labelField
  ]);


  useEffect(() => {
    // 如果父组件传递了为 true 的 displayLabel 属性或者没有传递 displayLabel，
    let elements: ReactElement[];
    if (actionGroups.length === 0) {
      elements = actions?.map((action) => buildActionElem(action));
    } else {
      const actionGroupElements = actionGroups.map((actionGroup) => {
        const menu = (
          <Menu>
            {(actionGroup.actions ?? []).map((action) => (
              <Menu.Item key={action.id}>
                <ActionButton
                  visiblePopover={visiblePopover}
                  setVisiblePopoverCallback={setVisiblePopoverCallback}
                  mode={mode}
                  domainName={domainName}
                  zIndex={zIndex + 1}
                  selectedData={selectedData}
                  action={action}
                  displayLabel={displayLabel ?? true}
                  fetchDataCallback={() => {
                    fetchDataCallback();
                    fetchActionList(modeParamForFetchAction, selectedIds);
                  }}
                  ownerClass={ownerClass}
                  ownerId={ownerId}
                  columnNameInOwnerClass={columnNameInOwnerClass}
                  labelField={labelField}
                />
              </Menu.Item>
            ))}
          </Menu>
        );
        const isVertical = (direction === 'vertical');
        const className = isVertical ?
          "action-group-container action-button-inactive" : "action-group-container link-icon-container";
        const spaceGap = isVertical ? 2 : 2;
        const width = (ref.current?.offsetWidth === 0) ?
          185 : (ref.current?.offsetWidth ?? 185);
        const align: { offset?: [number, number] } | undefined = isVertical ?
          { offset: [width, -33] } : undefined;
        return (
          <span key={actionGroup.id} onClick={() => {
            if (visiblePopover != null) {
              setVisiblePopoverCallback(undefined);
            }
          }}>
            <Dropdown
              overlay={menu}
              key={actionGroup.id}
              trigger={['click']}
              overlayClassName={`action-group-dropdown-${direction ?? 'horizontal'}`}
              placement={isVertical ? 'bottomLeft' : undefined}
              // getPopupContainer={(triggerNode) => {
              //   return triggerNode ?? document.body;
              // }}
              align={align}
            >
              <span
                className={className}
                onClick={(e) => e.preventDefault()}>
                <Space direction="horizontal" size={spaceGap} title={actionGroup.helpText ?? ''}>
                  {actionGroup.icon && <ActionIcon icon={actionGroup.icon} />}
                  {!actionGroup.icon && <CaretDownOutlined />}
                  <span className="action-label">
                    {t(actionGroup.label ?? actionGroup.name)}
                  </span>
                  {alignDirection === 'horizontal' && <CaretDownOutlined style={{
                    border: 0, width: "8px", height: "8px", paddingTop: "6px"
                  }} />}
                  {alignDirection === 'vertical' && <CaretRightOutlined style={{
                    paddingTop: "6px", marginLeft: "-6px"
                  }} />}
                </Space>
              </span>
            </Dropdown>
          </span>
        );
      });

      const actionsWithoutGroup = actions.filter((action) => !action.group);
      const actionsWithoutGroupElements = actionsWithoutGroup.map((action) => buildActionElem(action));

      elements = [(
        <Space
          key="has-group-actions"
          size={4}
          direction={alignDirection}
        >
          {actionsWithoutGroupElements}
          <Space direction={alignDirection} size={8}>
            {actionGroupElements}
          </Space>
        </Space>
      )];
    }
    setElements(elements);
  }, [
    actions, visiblePopover, domainName, mode, zIndex, actionGroups, displayLabelProp,
    columnNameInOwnerClass, isSingleMode, fetchDataCallback, fetchActionList, modeParamForFetchAction,
    ownerId, ownerClass, selectedData, selectedIds, setVisiblePopoverCallback, t, direction,
    alignDirection, displayLabel, buildActionElem, ref.current?.offsetWidth, labelField
  ]);

  const emptyMsg = (isMultipleMode && displayLabelProp !== false) ?
    t("No multiple action defined, unselected all rows to show class actions") : "";
  const elementWithSpace = (<Space
    direction={alignDirection}
    size={0}
  >
    {elements}
  </Space>);
  return loading ? (<LoadingOutlined style={{
    margin: "auto",
    width: "100%"
  }} />) : (
    <>{(actions?.length > 0) ?
      (<span ref={ref} style={{ display: "block" }}>{elementWithSpace}</span>) : emptyMsg}</>
  );
};

export default ActionButtons;
