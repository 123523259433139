import { SERVER_URL } from "@config/base";
import { fetchUrlWithToken, requestUrlAndGetPromise } from "@utils/FetchUtils";


const CHUNK_SIZE = 5242880;

export interface UploadedFile {
  id: number;
  url: string;
}

let uploading_count = 0;
const awaiting_upload_promise_list: Array<() => void> = [];

const awaitUpload = async (): Promise<void> => {
  if (uploading_count < 2) {
    ++uploading_count;
    return Promise.resolve();
  }
  return new Promise<void>((resolve) => {
    awaiting_upload_promise_list.push(() => resolve());
  });
};

const finishUpload = (): void => {
  --uploading_count;
  if (uploading_count < 2) {
    const resolve = awaiting_upload_promise_list.shift();
    if (resolve) {
      resolve();
    }
  }
};

const uploadFile = async (file: Blob): Promise<UploadedFile> => {
  await awaitUpload();
  try {
    const formData = new FormData();
    formData.append('upload', file);
    return fetchUrlWithToken(`${SERVER_URL}/attachment/upload`, {
      method: 'POST',
      body: formData,
    });
  } finally {
    finishUpload();
  }
};

const mergeFiles = async (filename: string, fileIds: number[]): Promise<UploadedFile> => {
  return requestUrlAndGetPromise(`${SERVER_URL}/attachment/merge`, {
    method: 'POST',
    body: JSON.stringify({
      filename,
      fileIds,
    }),
  });
};

export const multipartUpload = async (filename: string, file: Blob): Promise<UploadedFile> => {
  const { size } = file;
  if (size < CHUNK_SIZE) {
    return uploadFile(file);
  }
  const partsPromise: Promise<UploadedFile>[] = [];
  for (let start = 0; start < size; start += CHUNK_SIZE) {
    const blob = file.slice(start, start + CHUNK_SIZE);
    partsPromise.push(uploadFile(blob));
  }
  const parts = await Promise.all(partsPromise);
  return await mergeFiles(filename, parts.map((part) => part.id));
};
