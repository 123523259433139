import React, { ReactElement } from "react";
import { ObjectValues, TableMetaProps } from "@props/RecordProps";
import { Popover } from "antd";
import { CloseCircleOutlined, TableOutlined } from "@ant-design/icons";
import SubTableForm from "./SubTableForm";

const SubTableFormDisplayComponentCell = (props: {
  zIndex: number,
  owner?: ObjectValues;
  column: TableMetaProps;
  ownerClass: string;
}): ReactElement => {
  const [open, setOpen] = React.useState(false);
  const { zIndex, owner, column, ownerClass } = props;
  return <Popover
    open={open}
    trigger="click"
    placement="bottom"
    title={<CloseCircleOutlined
      style={{
        position: 'absolute',
        right: 15,
        top: 10,
      }}
      onClick={() => setOpen(false)}
    />}
    content={
      <div style={{
        marginTop: 20,
      }}>
        <SubTableForm
          column={column}
          owner={owner}
          editMode={false}
          ownerClass={ownerClass}
          zIndex={zIndex}
        />
      </div>
    }
    overlayStyle={{
      zIndex: zIndex + 2,
      maxWidth: 800,
    }}
  >
    <TableOutlined onClick={() => setOpen(!open)}/>
  </Popover>;
};

export default SubTableFormDisplayComponentCell;
