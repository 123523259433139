import React, { useMemo } from "react";
import { Popover, Steps, Tag } from "antd";
import { DynamicIcon } from "../../components";
import { StepsProps } from "antd/es/steps";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";

export interface StepProps {
  title?: string;
  description?: string;
  subTitle?: string;
  icon?: string;
}

export interface PopOverStepsComponentProps {
  current: number;
  label: string;
  status?: 'wait' | 'process' | 'finish' | 'error';
  steps: StepProps[];
}

export interface PopOverStepsCellProps {
  componentProps?: PopOverStepsComponentProps;
  progressExtInfo?: Partial<StepsProps>;
}

const getStatusTag = (label: string, status?: 'wait' | 'process' | 'finish' | 'error'): React.ReactElement => {
  switch (status) {
    case 'process':
      return <Tag icon={<SyncOutlined spin/>} color="processing">
        {label}
      </Tag>;
    case 'finish':
      return <Tag icon={<CheckCircleOutlined/>} color="success">
        {label}
      </Tag>;
    case 'error':
      return <Tag icon={<CloseCircleOutlined/>} color="error">
        {label}
      </Tag>;
    default:
      return <Tag icon={<ClockCircleOutlined/>} color="default">
        {label}
      </Tag>;
  }
};

const PopOverStepsCell: React.FC<PopOverStepsCellProps> = (props: PopOverStepsCellProps) => {
  const { componentProps, progressExtInfo } = props;
  const items = useMemo(() => componentProps ? componentProps.steps?.map((step) => ({
    ...step,
    icon: step.icon ? <DynamicIcon type={step.icon}/> : undefined,
  })) : undefined, [componentProps]);
  const currentItem = useMemo(() => items && componentProps?.current !== undefined ? items[componentProps.current] : undefined
    , [items, componentProps]);

  if (!componentProps) {
    return <></>;
  }

  if (!currentItem) {
    return getStatusTag(componentProps.label, componentProps.status);
  }

  return <Popover
    trigger="hover"
    content={<div style={{
      maxWidth: '40vw',
      overflow: "auto",
    }}>
      <Steps
        {...progressExtInfo}
        {...componentProps}
        items={items}
      />
    </div>}
  >
    {getStatusTag(componentProps.label, componentProps.status)}
  </Popover>;
};

export default PopOverStepsCell;
