import React, { ReactElement, useEffect } from 'react';
import { SERVER_URL } from "@config/base";
import { getAccessToken } from "@utils/TokenUtils";
import { useResizeDetector } from "react-resize-detector";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export interface DynamicFrameComponentProps {
  formId: number;
}

const DynamicFrameComponent: React.FC<DynamicFrameComponentProps> = (props): ReactElement => {
  const { formId } = props;

  const { ref } = useResizeDetector();
  const rect = ref.current?.getBoundingClientRect();
  const fullscreenHandler = useFullScreenHandle();

  useEffect(() => {
    const messageListener = (e: MessageEvent): void => {
      if (e.origin === SERVER_URL) {
        switch (e.data) {
          case 'entryDynamicFrameFullscreen':
            fullscreenHandler.enter();
            break;
          case 'exitDynamicFrameFullscreen':
            fullscreenHandler.exit();
            break;
        }
      }
    };
    window.addEventListener('message', messageListener);
    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [fullscreenHandler]);

  return <div>
    <FullScreen handle={fullscreenHandler}>
      <div
        ref={ref}
      >
        <iframe
          src={`${SERVER_URL}/frame?formId=${formId}&access_token=${getAccessToken()}`}
          referrerPolicy='unsafe-url'
          style={{
            width: rect ? `calc(100vw - ${rect.left}px + 8px)` : "100%",
            height: rect ? `calc(100vh - ${rect.top}px)` : "100%",
            position: "relative",
            left: -8,
            border: 'none',
            overflow: "auto",
          }}
        />
      </div>
    </FullScreen>
  </div>;
};

export default DynamicFrameComponent;
