import React, { useEffect, useMemo } from "react";
import {
  GetColumnValueCallback,
  RecordProps,
  SaveOptionProps,
  SaveRecordProps,
  Store,
  TableMetaProps
} from "@props/RecordProps";

import './entityAttributes.less';

import { Empty, Form, FormInstance, Space, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { EntityAttributeValues, getEntityAttributeMeta, } from "./EntityAttributesUtils";
import { EntityAttributesCell } from "./EntityAttributesCell";
import { transformRecord } from "@kernel/DisplayComponentsMapping";
import { getCustomValueCallback } from "@utils/FormUtils";
import { EVENT_BUS, resolvePath } from "@utils/eventBus/EventBus";

export interface EntityAttributesFormProps {
  column?: TableMetaProps;
  value?: EntityAttributeValues;
  ownerClass: string;
  // columnKey: string;
  owner?: SaveRecordProps;
  form?: FormInstance;
  editMode: boolean;
  zIndex: number;
  saveOptions?: SaveOptionProps;
  onValuesChange?: (changedValues: Store, allValues: Store) => void;
  path?: string;
}

export interface DisplayRenderProps {
  record: EntityAttributeValues;
}

export interface EditableRenderProps {
  record: EntityAttributeValues;
}

const DEFAULT_GROUP_NAME = -1;

const extractObjectId = (value: unknown): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'object') {
    return (value as RecordProps).id;
  } else {
    return value as number;
  }
};

const EntityAttributesTable = (props: EntityAttributesFormProps): React.ReactElement<EntityAttributesFormProps> => {
  const {
    column, ownerClass, owner, zIndex, path,
    editMode, form, saveOptions, value,
  } = props;

  const [loading, setLoading] = React.useState<boolean>(true);
  const [attributeMetas, setAttributeMetas] = React.useState<TableMetaProps[]>();
  const [attributeValues, setAttributeValues] = React.useState<EntityAttributeValues>({});
  const [templateDomainId, setTemplateDomainId] = React.useState<number | undefined>(undefined);

  const [innerForm] = Form.useForm();

  const templateFieldName = column?.templateFieldName;
  const templateDomainName = column?.templateDomainName;
  const extInfo = column?.extInfo;
  const ownerId = owner?.id;

  useEffect(() => {
    if (templateFieldName) {
      if (form) {
        setTemplateDomainId(extractObjectId(form.getFieldValue(templateFieldName)));
      } else if (owner) {
        setTemplateDomainId(extractObjectId(owner[templateFieldName]));
      }
    }
  }, [templateFieldName, form, owner]);

  useEffect(() => {
    if (path && templateFieldName) {
      return EVENT_BUS.on(resolvePath(path, `../${templateFieldName}`),
        (event) => {
          const { payload } = event;
          setTemplateDomainId(extractObjectId(payload));
        });
    }
  }, [path, templateFieldName]);

  useEffect(() => {
    if (column?.key && templateFieldName && saveOptions) {
      saveOptions.dynamicTemplateAttributesFieldOptions[column.key] = {
        templateFieldName: templateFieldName,
      };
    }
  }, [templateFieldName, column?.key, saveOptions]);

  useEffect(() => {
    if (!form || !column?.key) {
      return;
    }
    const getColumnValueRecord: Record<string, GetColumnValueCallback> = getCustomValueCallback(form);
    if (getColumnValueRecord) {
      getColumnValueRecord[column.key] = () => innerForm.getFieldsValue(true);
    }
  }, [form, column?.key, innerForm]);

  useEffect(() => {
    // console.log("getEntityAttributeMeta", templateDomainId, templateDomainName);
    if (templateDomainId && templateDomainName) {
      getEntityAttributeMeta(templateDomainName, templateDomainId).then((resp) => setAttributeMetas(resp.fields));
    } else {
      setAttributeMetas([]);
    }
  }, [templateDomainId, templateDomainName]);

  useEffect(() => {
    if (!attributeMetas) {
      return;
    }
    if (!column) {
      setLoading(false);
      return;
    }
    let res: RecordProps;
    if (value) {
      res = value as RecordProps;
    } else {
      res = {} as RecordProps;
      attributeMetas.forEach((meta) => {
        res[meta.key] = meta.defaultValue;
      });
    }
    const data = transformRecord(attributeMetas, res as RecordProps);
    innerForm.setFieldsValue(data);
    setAttributeValues(data);
    setLoading(false);
  }, [value, ownerId, column, ownerClass, innerForm, templateDomainId, templateDomainName, attributeMetas]);

  const tableColumns = useMemo(() => {
    if (!attributeMetas) {
      return undefined;
    }
    const filteredMetas = attributeMetas.filter((c) => c.display !== false);
    const columnGroupsRecord: Record<number, TableMetaProps[]> = {};
    const tableGroupsRecord: Record<string, { displaySequence: number }> = {};
    const tableRowColumnToFieldPropsRecord: Record<string, TableMetaProps> = {};
    const columnGroups: number[] = [];
    const allRows = new Set<number>();
    filteredMetas.forEach((meta) => {
      if (meta.extInfo?.entityAttributesCell) {
        const sameLabelColumnRecord = (tableGroupsRecord[meta.extInfo.entityAttributesCell.tableColumnLabel] = tableGroupsRecord[meta.extInfo.entityAttributesCell.tableColumnLabel] ?? {
          displaySequence: meta.displaySequence ?? 0,
          rowRecord: {},
        });
        if (meta.displaySequence) {
          sameLabelColumnRecord.displaySequence = Math.max(sameLabelColumnRecord.displaySequence, meta.displaySequence);
        }
        tableRowColumnToFieldPropsRecord[`${meta.extInfo.entityAttributesCell.tableColumnLabel}-${meta.extInfo.entityAttributesCell.tableRow}`] = meta;
        allRows.add(meta.extInfo.entityAttributesCell.tableRow);
      } else {
        const rowSequence = meta.extInfo?.rowSequence ?? DEFAULT_GROUP_NAME;
        if (!columnGroupsRecord[rowSequence]) {
          columnGroupsRecord[rowSequence] = [];
          columnGroups.push(rowSequence);
        }
        columnGroupsRecord[rowSequence].push(meta);
      }
    });

    const tableArray = columnGroups
      .sort((a, b) => a - b)
      .map(groupIndex => {
        const columns = columnGroupsRecord[groupIndex];
        const result: ColumnsType<EntityAttributeValues> = columns
          .sort((a, b) => (a.displaySequence ?? 0) - (b.displaySequence ?? 0))
          .map((c) => ({
            key: c.key,
            dataIndex: c.key,
            title: c.title,
            ellipsis: true,
            render: () => <EntityAttributesCell
              column={c}
              record={attributeValues}
              editMode={editMode}
              innerForm={innerForm}
              zIndex={zIndex}
              ownerId={ownerId}
              path={path}
              ownerClass={ownerClass}
            />,
          }));
        return <Table
          key={groupIndex}
          pagination={false}
          className="entity-attributes-table-container"
          columns={result}
          // bordered
          dataSource={[attributeValues]}
          size="small"
        />;
      });

    if (extInfo?.entityAttributesTable?.exchangeRowAndColumns) {
      const { rowLabels: columnLabels } = extInfo.entityAttributesTable;
      const rows: Array<{ rowLabel: string; displaySequence: number; }> = [];
      Object.keys(tableGroupsRecord).forEach((columnLabel) => {
        const { displaySequence } = tableGroupsRecord[columnLabel];
        rows.push({
          rowLabel: columnLabel,
          displaySequence,
        });
      });
      const sortedRows = rows.sort((a, b) => a.displaySequence - b.displaySequence);
      const tableColumns: ColumnsType<{ rowLabel: string }> = [];
      tableColumns.push({
        key: '@FIXED@',
        dataIndex: '@FIXED@',
        title: '',
        fixed: 'left',
        width: extInfo.entityAttributesTable?.rowLabelWidth ?? 180,
        ellipsis: true,
        render: (value: unknown, { rowLabel }) => {
          return rowLabel;
        }
      });
      [...allRows.values()]
        .sort((a, b) => a - b)
        .forEach((columnSequence) => {
          const columnLabel = columnLabels[String(columnSequence)];
          tableColumns.push({
            key: columnLabel,
            dataIndex: columnLabel,
            title: columnLabel,
            ellipsis: true,
            width: extInfo.entityAttributesTable?.columnWidth,
            render: (value: unknown, rowId: { rowLabel: string }) => <EntityAttributesCell
              column={tableRowColumnToFieldPropsRecord[`${rowId.rowLabel}-${columnSequence}`]}
              record={attributeValues}
              editMode={editMode}
              innerForm={innerForm}
              zIndex={zIndex}
              ownerId={ownerId}
              path={path}
              ownerClass={ownerClass}
              style={{
                minWidth: "100%",
              }}
            />,
          });
        });
      if (sortedRows.length > 0) {
        tableArray.push(<Table
          key={'TABLE'}
          pagination={false}
          scroll={{
            x: 'max-content',
            y: '50vh',
          }}
          columns={tableColumns}
          // bordered
          dataSource={sortedRows}
          size="small"
        />);
      }
    } else {
      const columns: Array<{ columnLabel: string; displaySequence: number; }> = [];
      const sortedRows = [...allRows.values()]
        .sort((a, b) => a - b)
        .map((id) => ({ id }));
      Object.keys(tableGroupsRecord).forEach((columnLabel) => {
        const { displaySequence } = tableGroupsRecord[columnLabel];
        columns.push({
          columnLabel,
          displaySequence,
        });
      });
      const tableColumns: ColumnsType<{ id: number }> = [];
      if (extInfo?.entityAttributesTable) {
        const { rowLabels } = extInfo.entityAttributesTable;
        tableColumns.push({
          key: '@FIXED@',
          dataIndex: '@FIXED@',
          title: '',
          fixed: 'left',
          width: 100,
          ellipsis: true,
          render: (value: unknown, { id }) => {
            return rowLabels[String(id)];
          }
        });
      }
      columns
        .sort((a, b) => a.displaySequence - b.displaySequence)
        .map((column) => {
          const { columnLabel } = column;
          return {
            key: columnLabel,
            dataIndex: columnLabel,
            title: columnLabel,
            ellipsis: true,
            render: (value: unknown, rowId: { id: number }) => <EntityAttributesCell
              column={tableRowColumnToFieldPropsRecord[`${columnLabel}-${rowId.id}`]}
              record={attributeValues}
              editMode={editMode}
              innerForm={innerForm}
              zIndex={zIndex}
              ownerId={ownerId}
              path={path}
              ownerClass={ownerClass}
              style={{
                minWidth: "100%",
              }}
            />,
          };
        })
        .forEach(c => tableColumns.push(c));
      if (sortedRows.length > 0) {
        tableArray.push(<Table
          key={'TABLE'}
          pagination={false}
          className="entity-attributes-table-container"
          scroll={{
            x: 'max-content',
            y: 'max-content',
          }}
          columns={tableColumns}
          // bordered
          dataSource={sortedRows}
          size="small"
        />);
      }
    }
    return tableArray;
  }, [attributeValues, innerForm, attributeMetas, zIndex, editMode, path, ownerId, extInfo, ownerClass]);

  // console.log("EntityAttributesTable: ", tableColumns, templateDomainId);

  return (loading ? <Spin/> :
      <Form
        form={innerForm}
        initialValues={attributeValues}
        component={false}
      >
        <div className="entity-attributes-table-panel">
          <Space direction="vertical" style={{
            maxWidth: '100%',
          }}>
            {!tableColumns || tableColumns.length === 0 ?
              <Empty className="empty-content" image={Empty.PRESENTED_IMAGE_SIMPLE}/> : tableColumns}
          </Space>
        </div>
      </Form>
  );
};


export default EntityAttributesTable;
